<script>
import BtnImport from './include/BtnImport'
import windowImportacaoUpload from '../windows/importacao'
import windowImportacao from '../windows/windowImportacao'
import {importacoes} from '@/domain/importador/services'
import * as Status from '../../../domain/importador/helpers/Status'

export default {
  name: 'Importador',
  mixins: [],
  components: {BtnImport},
  data () {
    return {
      importacoes: null,
      loadingImportacoes: false
    }
  },
  computed: {
    Status () {
      return Status
    }
  },
  mounted () {
    document.querySelector('.u-erp-layout-container').classList.add('app-importador')
    this.loadImportacoes()
  },
  beforeDestroy () {
    document.querySelector('.u-erp-layout-container').classList.remove('app-importador')
  },
  methods: {
    windowImportacaoUpload,
    loadImportacoes () {
      this.loadingImportacoes = true
      importacoes()
        .then((response) => {
          console.log(response.data)
          this.importacoes = response.data
          this.loadingImportacoes = false
        })
        .catch(error => {
          this.alertApiError(error.response)
          this.loadingImportacoes = false
        })
    },
    windowImportacao
  },
  meta: {
    title: 'Importador',
    name: 'Importador'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap importador-container h-full">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="importador">
          <h2 class="i-title">Olá, estamos aqui para facilitar seu trabalho 🎉</h2>
          <p class="m-b-xxl">Muito cuidado com esta operação. Recomendamos fortemente utilizar primeiro o ambiente de
            homologação para testar suas novas migrações.</p>

          <div class="import-options">
            <btn-import @click.native="windowImportacaoUpload('pessoa')" title="Importar Pessoas" icon="icon fal fa-person-carry" desc="0 em andamento"
                        class="color1" />
            <btn-import @click.native="windowImportacaoUpload('bem')" title="Importar Bens" icon="icon fal fa-clipboard" desc="0 em andamento" class="color2" />
            <btn-import @click.native="windowImportacaoUpload('leilao')" title="Importar Leilões" icon="icon fal fa-gavel" desc="0 em andamento" class="color3" />
            <btn-import @click.native="windowImportacaoUpload('lote')" title="Importar Lotes" icon="icon fal fa-clipboard" desc="0 em andamento" class="color3" />
            <btn-import @click.native="windowImportacaoUpload('fipe')" title="Importar Tabela Fipe" icon="icon fal fa-car" desc="0 em andamento" class="color3" />
          </div>

          <div class="import-options">
            <btn-import title="Exportar Backup" icon="icon fal fa-file-export" desc="Exporta toda a base de dados"
                        class="color5" />
          </div>

          <div class="box1 m-t-xxl">
            <div class="content">
              <header>
                <h2>Explore o andamento da migração</h2>
                <div class="col-search">
                  <div class="search">
                    <input placeholder="Pesquisar" class="input-search" value="">
                    <span class="icon fal fa-search"></span>
                  </div>
                  <button type="button" title="Buscar na migração">
                    Buscar na migração
                  </button>
                </div>
              </header>

              <div class="body">
                <div class="lista-importacoes">
                  <div v-if="loadingImportacoes">Verificando importações disponíveis <i class="fa fa-spin fa-spinner"></i> </div>
                  <table v-if="importacoes && importacoes.length" class="erp-table w-full">
                    <thead>
                      <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left">Tipo de importação</th>
                        <th class="text-left">Situação</th>
                        <th class="text-left">Data Início</th>
                        <th class="text-left">Data Término</th>
                        <th class="text-left">Quantidade de registros</th>
                        <th class="text-left">Sucesso</th>
                        <th class="text-left">Falha</th>
                        <th class="text-right">Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr v-for="importacao in importacoes" :key="importacao.id">
                      <td class="text-left uppercase">{{importacao.id}}</td>
                      <td class="text-left uppercase">{{importacao.type}}</td>
                      <td class="text-left uppercase">{{Status.Status[importacao.status].title}}</td>
                      <td class="text-left"><span v-if="importacao.dataInicio">{{ importacao.dataInicio.date|formatDate}}</span><span v-else>-</span></td>
                      <td class="text-left"><span v-if="importacao.dataTermino">{{ importacao.dataTermino.date|formatDate}}</span><span v-else>-</span></td>
                      <td class="text-left">{{importacao.totalItems}}</td>
                      <td class="text-left">{{importacao.sucessos}}</td>
                      <td class="text-left">{{importacao.falhas}}</td>
                      <td class="text-right">
                        <a @click="windowImportacao(importacao.id)">Abrir</a>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <div v-else><span v-if="!loadingImportacoes">Nenhuma importação até o momento</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
