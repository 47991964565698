export default function (id) {
  let window = `importador.${id}`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Importação',
    width: '98%',
    height: '98%',
    minHeight: '500px',
    backdrop: true,
    // fullscreen: true,
    clickOutside: false,
    // windowClass: 'erp-window-modern',
    contentClass: 'overflow-hidden',
    props: {
      id
    }
  }, () => import('../components/importacao/Importacao'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        created: (wid, val) => {
          this.loadImportacoes && this.loadImportacoes()
        }
      })
    }) // return wid
}
