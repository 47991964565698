export const STATUS_ANALISE = 0
export const STATUS_EM_PROCESSAMENTO = 1
export const STATUS_CONCLUIDO_SUCESSO = 100
export const STATUS_CONCLUIDO_COM_FALHAS = 101
export const STATUS_CANCELADO = 99

export const Status = {
  [STATUS_ANALISE]: {title: 'Em análise', class: ''},
  [STATUS_EM_PROCESSAMENTO]: {title: 'Em processamento', class: 'text-primary'},
  [STATUS_CONCLUIDO_SUCESSO]: {title: 'Concluído com sucesso', class: 'text-positive'},
  [STATUS_CONCLUIDO_COM_FALHAS]: {title: 'Concluído com falhas', class: 'text-warning'},
  [STATUS_CANCELADO]: {title: 'Cancelado', class: 'text-negative'},
}
