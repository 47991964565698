<template>
    <div class="i-option">
      <a>
        <span :class="icon"></span>
        <h2 class="title">{{title}}<span>{{desc}}</span></h2>
      </a>
    </div>
</template>

<script>
export default {
  name: 'BtnImport',
  props: ['icon', 'title', 'desc']
}
</script>
